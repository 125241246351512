import { Box, Typography, Tooltip } from '@mui/material'
import LinearProgress from "@mui/material/LinearProgress"
import { useState, useEffect, useRef, useContext } from 'react'
import { getUploadClient } from '../utils/UploadHelper'
import { insertDocument } from '../utils/DMSClient'
import { Icon } from '@fluentui/react/lib/Icon'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import ReplaySharpIcon from '@mui/icons-material/ReplaySharp'
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded'
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress'
import { UserContext } from '../utils/UserContext'

//Makes the Blob Name unique while keeping the file extension at the end
function getBlobName(fileName: string){
    const lastDotPosition = fileName.lastIndexOf(".")
    const identifier = "-".concat(new Date().valueOf().toString())
    return lastDotPosition > -1 ? fileName.slice(0,lastDotPosition) + identifier + fileName.slice(lastDotPosition) 
                                : fileName + identifier
}

//Shows individual file upload progress, allows upload retry, performs cosmos sync after upload and allows retry there separately
const UploadProgress =  (props: any) : JSX.Element => {
    const [uploadPercent, setUploadPercent] = useState(0)
    const [uploadFailed, setUploadFailed] = useState(false)
    const [uploadSyncStarted, setUploadSyncStarted] = useState(false)
    const [uploadSyncFailed, setUploadSyncFailed] = useState(false)
    const [uploadSynced, setUploadSynced] = useState(false)
    const calledOnce = useRef(false);//Ensures that the useEffect is called only once
    const blobName = getBlobName(props.chosenFile.name)
    const userInfo = useContext(UserContext);
    //Prepares the payload and updates cosmos db
    const handleAdd = async () => {
        try{
            setUploadSynced(false)
            setUploadSyncFailed(false)
            setUploadSyncStarted(true)
            const documentBody = {//Extra properties can be supplied here. API is not supposed to break if the corresponding fields are not present in the model
                solutionName: props.solutionName,
                dmsDocuments: [{
                    name: props.chosenFile.name,
                    parentId: props.chosenDirectoryId ?? "",
                    isDirectory: false,
                    fileStorageContainer: props.blobContainer,//Replace with blob container name that has the file
                    fileStorageDirectory: "",
                    fileStorageName: blobName,
                    version: "1.0",
                    properties: {
                        uploadedOn: new Date().toUTCString(),
                        uploadedBy: `${userInfo.currentUser}`,
                        lastModifiedOn: new Date().toUTCString(),
                        size: props.chosenFile.size,
                        type: props.chosenFile.type,
                        description: "TBD"
                    }
                }]
            };

            const result = await insertDocument(documentBody);
            if(result.length > 0) {//Insert succeeded
                props.setTreeData((treeData: any) => [
                    ...treeData,                 
                    ...result
                ])
                setUploadSynced(true)
            } else {//Handle errors here that happen AFTER reaching the service
                console.log(`insertDocument API threw an error. Result recieved: ` + result)
                setUploadSynced(false);
                setUploadSyncFailed(true);
            }
        } catch (error) {//Handle errors here that happen BEFORE reaching the service
            console.log(`insertDocument API threw an error. Error: ` + error)
            setUploadSynced(false);
            setUploadSyncFailed(true);
        }
      };

    //Uploads the file and handles showing the status
    const UploadAndShow = async () => {
        console.log(`getting upload client for:` + blobName)
        const uploadClient = await getUploadClient(props.solutionName, props.blobContainer, blobName)
        const promise = uploadClient.uploadData(props.chosenFile, {
           // maxSingleShotSize: 40000000, //Set to ~40MB until concurrency kicks in. Default is 256 MB. 
            concurrency: 1,
            blobHTTPHeaders: { blobContentType: props.chosenFile.type },
            onProgress: (ev: any) => {
                setUploadPercent((ev.loadedBytes / props.chosenFile.size) * 100)
                console.log('file: ' + blobName + ' uploaded so far:' + ev.loadedBytes)
            }
            }) .then(async () => {
                try {
                    await handleAdd()
                } catch (error: any) {//Handle errors here that happen BEFORE reaching the service
                    console.log(`insertDocument API threw an error: ` + error)
                    setUploadSynced(false)
                    setUploadSyncFailed(true)
                }
                return "done" //Promise.all(props.promises) can receive this value as each process completes. Return as needed.
            }) 
            .catch((error: any) => {
                setUploadPercent(0)
                setUploadFailed(true)
                console.log(`upload failed: ` + error)
            })
        props.setPromises((promises: any) => {
            console.log(`setting promise:` + promise)
            return [...promises, promise]})
    }

    useEffect(() => {//This component will be refreshed on every change to uploadPercent
        if (calledOnce.current) {
            console.log(`avoiding repeated upload`)
            return;//Ensure upload happens only once. We see it trigger twice without this with the current react version.
        }

        (async function(){ 
            calledOnce.current = true
            await UploadAndShow()
        })()
    }, [])

    const retryUpload = async () => {
        setUploadFailed(false)
        await UploadAndShow()
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center"}}>
            <Box sx={{ display: "flex", m: 1 }}>
                <Box sx={{mr:1}}><Icon {...getFileTypeIconProps({ extension: props.chosenFile.name?.split('.').pop(), size: 20, imageFileType: 'svg' }) } /></Box>
                {props.chosenFile.name}
            </Box>
            <Box sx={{ width: "100%", mr: 1 }}>
                {uploadFailed ? 
                <Tooltip title='Retry upload'><ReplaySharpIcon onClick={retryUpload}/></Tooltip> : 
                <LinearProgress variant="determinate" value={Math.round(uploadPercent)} />}
            </Box>
            <Box sx={{display: "flex", alignItems: "flex-end", minWidth: 40, mr: 1 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(uploadPercent)}%`}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "flex-end", m: 1 }}>
            {uploadSyncStarted ? (uploadSynced ? <DoneIcon fontSize="small" color="success"/>
                                                : (uploadSyncFailed ? <Tooltip title='Retry sync'><ReplaySharpIcon fontSize="small" color="action" onClick={handleAdd}/></Tooltip> 
                                                                    : <CircularProgress size="1.35em" color="success"/>))
                                : <Tooltip title='To be synced after upload'><HourglassTopRoundedIcon color="disabled" fontSize="small"/></Tooltip>}
            </Box>
        </Box>
    );
}
export default UploadProgress