import React, { forwardRef } from "react";
import { DragLayer } from "./DragLayer";
import { Container } from "./Container";
import { Providers } from "./providers";
import { TreeMethods, TreeProps} from './types';
import styles  from "./CustomNode.module.css";

function TreeInner<T>(
  props: TreeProps<T>,
  ref: React.ForwardedRef<TreeMethods>
) {
  return (
    <div className={styles.app}>
    <Providers {...props} 
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget
            }} 
            treeRef={ref}>
      {props.dragPreviewRender && <DragLayer />}
      <Container parentId={props.rootId} depth={0} />
    </Providers>
    </div>
  );
}

const Tree = forwardRef(TreeInner) as <T = unknown>(
  props: TreeProps<T> & { ref?: React.ForwardedRef<TreeMethods> }
) => ReturnType<typeof TreeInner>;

export { Tree };
