import { SortCallback } from "../types";

export const compareItems: SortCallback = (a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }

  return 0;
};
