import { NodeModel } from "../types";

export const isAncestor = (
  tree: NodeModel[],
  sourceId: NodeModel["id"],
  targetId: NodeModel["id"]
): boolean => {
  if (targetId === "") {
    return false;
  }

  const targetNode = tree.find((node) => node.id === targetId);

  if (targetNode === undefined) {
    return false;
  }

  if (targetNode.parentId === sourceId) {
    return true;
  }

  return isAncestor(tree, sourceId, targetNode.parentId);
};
