import SampleData from "./sample_data.json";
import { searchDocument } from "../utils/DMSClient";
import { addParentAndIsDirectoryByRelativeUri } from '../utils/ResponseHelper';

export const RootStore = SampleData;
export const ChosenNode = null; 

export async function handleTreeDataChanges(){    
    // Call DMS API and return response to tree view    
    const solutionName = process.env.REACT_APP_SOLUTION_NAME!;
    const response = await searchDocument(solutionName, "", "");
    return addParentAndIsDirectoryByRelativeUri(response);
}
