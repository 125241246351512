import { Alert, Avatar, Box, Button, Checkbox, Collapse, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useCallback, useState, useContext, useEffect } from 'react';
import { AccountCircle, ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import { UserContext } from "../utils/UserContext";
import { t } from "../utils/Internationalization";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import { getApplicationRoles, getConfiguration, patchConfiguration } from "../utils/DMSClient";
import { SolutionContext } from '../utils/SolutionContext';
import { Theme } from '@mui/material/styles';
import { borderRight } from "@mui/system";
import { ConfigContext } from '../utils/ConfigurationContext';

export const Profile = () => {
    const isAuthenticated = useIsAuthenticated();
    return isAuthenticated ? <SignOut/> : null;
};

export const SignOut = (() => {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showProfile, setShowProfile] = useState(false);
    const handleSignOut = () => {
        instance.logoutRedirect();
    };

    const handleOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        },
        []
      );
    const handleClose = useCallback(() => {
    setAnchorEl(null);
    }, []);
    const onClick = () => {
      setShowProfile(true);
      setAnchorEl(null);
    }
    return (<div>
        <IconButton onClick={handleOpen} color="secondary" size="large">
          <AccountCircle />
        </IconButton>
        <Menu   
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={anchorEl !== null}
          onClose={handleClose}
        >
        <MenuItem onClick={onClick}>
          <ListItemIcon>
              <Avatar sx={{ width: 25, height: 25 }} color="secondary"/>
          </ListItemIcon>  {t('Profile')}
        </MenuItem>
         <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" color="primary" />
          </ListItemIcon>
          {t('Logout')}
        </MenuItem>
        </Menu>
        <UserProfile showProfile = {showProfile} setShowProfile = {setShowProfile}/>
      </div>);
});


export const DisplayName = (() => {
    const userInfo = useContext(UserContext);

    return(
        <Typography variant="h6" color="secondary" sx={{marginLeft: "auto"}}>{userInfo.currentUser}</Typography>);
});

export const UserProfile = ({showProfile, setShowProfile}: any) => {
  const userInfo = useContext(UserContext);
  const onClose = () => {
    setShowProfile(false);
  }
  return (<Drawer open = {showProfile} onClose = {onClose} anchor='bottom' sx={{m:2}}>
            <Grid container direction="column" justifyContent="center" alignItems="center" paddingTop="20px">
              <ListItemIcon>
                  <Avatar sx={{ width: 100, height: 100 }}/>
              </ListItemIcon><br/>
              <h1>{userInfo.currentUser}</h1><br/>
              <h2>{userInfo.currentUserMail}</h2><br/>
            </Grid>
            <Grid container direction="column" paddingBottom="10px" justifyContent="center" alignItems="center">
              <Button color="primary" variant="contained" onClick={onClose}>{t('Close')}</Button>
            </Grid>
          </Drawer>);
}
export interface AlertsProps { 
  show?: boolean
  severity: 'error' | 'success' | 'warning' | 'info'
  message:string}
export const AdminSettings = () => {
  const solutionContext = useContext(SolutionContext);
  const configContext = useContext(ConfigContext);
  const [checkUpdate, setCheckUpdate] = useState(true);
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState<string>();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<AlertsProps>({
                                                  show: false,
                                                  severity: "info",
                                                  message: ""});
  const [applicationRoles, setApplicationRoles] = useState();
  const handleClick = () => {
    setVisible(true);
  }
  const handleClose = () => {
    setVisible(false);
  }
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    configContext?.setCheckUpdate(!configContext.checkUpdate)
    getApplicationRoles().then(res => {setApplicationRoles(res); return null}).catch(e => console.log(e))
  }, [checkUpdate, solutionContext.SolutionName]);
  return (<>
    <IconButton onClick={handleClick}><SettingsIcon color="secondary"/></IconButton>
    <Drawer open = {visible} onClose = {handleClose} anchor='bottom' sx={{m:2}}>
      <Grid container spacing={2}>
      <Grid item xs={4} textAlign="center">
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <Typography variant="h6" color="primary" paddingTop="5%" paddingBottom="2.5%">
              Configuration Settings
            </Typography>}
        >
          <ListItemButton onClick={handleToggle}>
            <ListItemIcon>
              <GroupsIcon color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Permissions" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 4 }} disablePadding>
              {["Editor", "Reader"].map((roleName) => (
                <ListItemButton selected={roleName === role} key={roleName} onClick={() => {setRole(roleName)}}>
                  <ListItemIcon/>
                  <ListItemText primary={roleName} primaryTypographyProps={{variant: "subtitle2"}}/>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        </Grid>
        {(role?.toLowerCase() === "editor") && <RoleBlade solutionContext={solutionContext} applicationRoles = {applicationRoles ?? []} assignedRoles={configContext?.editorRoles ?? []} setCheckUpdate = {setCheckUpdate} checkUpdate = {checkUpdate} bladeName="editor" open={open} alert={alert} setAlert={setAlert} setVisible={setVisible}/>}
        {(role?.toLowerCase() === "reader") && <RoleBlade solutionContext={solutionContext} applicationRoles = {applicationRoles ?? []} assignedRoles={configContext?.readerRoles ?? []} setCheckUpdate = {setCheckUpdate} checkUpdate = {checkUpdate} bladeName="reader" open={open} alert={alert} setAlert={setAlert} setVisible={setVisible}/>}
        </Grid>
          </Drawer>
        </>
  );
}


export const RoleBlade = ({solutionContext, applicationRoles, assignedRoles, setCheckUpdate, checkUpdate, bladeName, open, alert, setAlert, setVisible}: any) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>(assignedRoles);
  const handleRoleClick = (args: any) => {
    !selectedRoles.includes((args.target.innerText) as string) ? setSelectedRoles([...selectedRoles, args.target.innerText]) 
                                                               : setSelectedRoles(selectedRoles.filter(val => val !== args.target.innerText))
  };
  const handleRoleUpdate = () => {
    const updateRoles = (bladeName === "reader" ? {
                            SolutionName:`${solutionContext.SolutionName}`,
                            SolutionConfiguration:
                            {
                              ConfigurationProperties:
                              {
                                  reader: selectedRoles.join(',')
                              }
                            }
                          }
                          : {
                            SolutionName:`${solutionContext.SolutionName}`,
                            SolutionConfiguration:
                            {
                              ConfigurationProperties:
                              {
                                  editor: selectedRoles.join(',')
                              }
                            }
                          })
    patchConfiguration(updateRoles)
    .then((res) => {
      setCheckUpdate(!checkUpdate);
      setAlert({
        show:true,
        severity: "success",
        message: "Roles updated successfully!"
      })
      setTimeout(() => setAlert({
        show:false
      }), 2000);
      console.log("success");
      return null;
    })
    .catch((e) => {
      console.log("Update configuration has failed.");
      setAlert({
        show:true,
        severity: "error",
        message: "Something went wrong. Please try again!"
      })
      setTimeout(() => setAlert({
        show:false
      }), 2000);
      console.log(e)
    })
  }
  const handleClose = () => {
    setVisible(false)
  }
  return open && <Grid item xs={4} textAlign="center" spacing={1}>
  {alert.show && <Notification message={alert.message} status={alert.severity}/>}
  <div className="ag-theme-material "><Grid container direction="column" justifyContent="left" alignItems="left">
  <Typography textAlign="left" color="primary" variant="body1" paddingBottom="2.5%" paddingTop="5%">
  These roles are from global iam configuration for this application. Please select the ones that apply for this level of permission
  </Typography>
  {applicationRoles.map((role: any) => (
  <ListItem key={role['displayName']} 
    secondaryAction={<Checkbox edge="start" disableRipple 
    checked={ selectedRoles.includes(role['displayName']) } name={role['displayName']} key={role['displayName']}/>}
    >
    <ListItemButton alignItems="flex-start" onClick={handleRoleClick}>
      <ListItemText primary={role['displayName']}/>
    </ListItemButton>
  </ListItem>))}
  </Grid>
  </div>
  <Box
      m={1}
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      paddingBottom="5%"
    >
      <Grid container direction="column" justifyContent="center" alignItems="center" paddingTop="2.5%">
        <Button variant="contained" color="primary" onClick={handleClose}> {t('Close')} </Button>
      </Grid>
      <Button variant="contained" color="primary" onClick={handleRoleUpdate} disabled={(selectedRoles.join(",") === assignedRoles.join(","))}>{t('Update')}</Button>
    </Box>
</Grid>
}
const Notification = ({message, status}: any) => {
  return <Snackbar open={true} autoHideDuration={6} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity={status} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
}
