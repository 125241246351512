import { Box, Typography, Button, Drawer, CircularProgress } from '@mui/material';
import { useState, useRef, useEffect, useMemo } from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import { t } from "../utils/Internationalization"
import UploadProgress from './UploadProgress';

//1. Show upload control to accept files for upload
//2. Upload files
//3. Merge into cosmos: Assign current folder as parent and insert new items
const FileUpload = (props: any): JSX.Element => {
  const chosenFiles = useRef<File[]>([]);
  const [acceptingFiles, setAcceptingFiles] = useState(false);
  const [canUpload, setCanUpload] = useState(false);

  const handleChange = (files: File[]) => {
    console.log('files changed to:' + files);
    chosenFiles.current = files;
    chosenFiles.current?.length > 0 ? setCanUpload(true) : setCanUpload(false);
    setAcceptingFiles(false);
  }

  const handleDrop = (files: File[], evt: any) => {
    console.log('files dropped:' + evt);
    setAcceptingFiles(true);
  }

  async function handleSubmit() {
    props.setUploading(true);
  }

  return (
    <Drawer open={props.uploadDrawer} anchor='bottom' sx={{m:2}}>
      <Box sx={{m:2}}>
        {/* <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <Typography>Upload under</Typography> <Typography sx={{fontWeight:'bold', ml:1}}>{props.chosenDirectory?.name ?? "Home"}</Typography>
        </Box> */}
        {props.uploading ? 
         (<ShowUploadProgress chosenFiles={chosenFiles} solutionName={props.solutionName} blobContainer={props.blobContainer} setUploadDrawer={props.setUploadDrawer} chosenDirectoryId={props.chosenDirectory?.id} treeData={props.treeData} setTreeData={props.setTreeData}/>)
        : (<><DropzoneArea useChipsForPreview onChange={handleChange} onDrop={handleDrop} filesLimit={10} maxFileSize={999999999999} showAlerts={['error']}/> {/*1 TB Upload limit per file*/}
              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                { acceptingFiles ? <CircularProgress sx={{ margin: 2 }} /> : 
                  <><Button sx={{ margin: 2 }} onClick={() => props.setUploadDrawer(false)}>{t('Cancel')}</Button>
                  {canUpload && <Button sx={{ margin: 2 }} variant="contained" onClick={handleSubmit}>{t('Start Upload')}</Button>}</>
                }
              </Box></>
          )
        }
      </Box>
    </Drawer>
  )
}

//Responsible for components that show the upload/sync progress and allow exiting the interface
const ShowUploadProgress = (props: any) : JSX.Element => {
  console.log("loading all files now")
  const progressItems : any = []
  const [promises, setPromises] = useState([]);
  props.chosenFiles.current?.map ((chosenFile: File) => (
    progressItems.push(UploadProgressItem(chosenFile, setPromises, props.solutionName, props.blobContainer, props.chosenDirectoryId, props.treeData, props.setTreeData))
  ))
  progressItems.push(<UploadCompletion key='uploadCompletion' setUploadDrawer={props.setUploadDrawer} promises={promises} expectedPromises={props.chosenFiles.current.length}/>)
  return progressItems;
}

//Provides file specific progress items that don't reload when promises change
// eslint-disable-next-line max-params
function UploadProgressItem(chosenFile: File, setPromises: any, solutionName: string, blobContainer: string, chosenDirectoryId: string, treeData: any, setTreeData: any){
  return useMemo(() => <UploadProgress key={chosenFile.name} setPromises={setPromises} solutionName={solutionName} blobContainer={blobContainer}
                                      treeData={treeData} setTreeData={setTreeData} chosenDirectoryId={chosenDirectoryId} 
                                      chosenFile={chosenFile}/>, [])
}

//This component shows up below all the progress bars. It will show a circular progress untill all promises are resolved. It then shows a Close button to exit the interface
const UploadCompletion = (props: any) : JSX.Element => {
  const [uploaded, setUploaded] = useState(false);
  useEffect(() => {
    //This will be called every time a promise is added to the array. We need to wait till the last one is added
    if(props.promises !== undefined && props.promises.length === props.expectedPromises){
      Promise.all(props.promises)
      .then(() => {
        console.log("All " + props.promises.length + " promises completed successfully")
        setUploaded(true)
        return true
      })
      .catch((error) => {
        console.error(error.message)
        setUploaded(true)
      });
    }
  }, [props.promises])

  return (
    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
      {uploaded ? <Button sx={{ margin: 2 }} variant="contained" onClick={() => props.setUploadDrawer(false)}>{t('Close')}</Button> :<CircularProgress sx={{ m: 2 }} />}
    </Box> 
  );
}
export default FileUpload;