/* eslint-disable react/prop-types */
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType, initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

initializeFileTypeIcons();
const iconSize = 20;
const iconImageFileType = 'svg';

export const TypeIcon = (props) => {
  if (props.isDirectory) {
    return <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: iconSize, imageFileType: iconImageFileType })} />
  }
  return <Icon {...getFileTypeIconProps({ extension: props.name?.split('.').pop(), size: iconSize, imageFileType: iconImageFileType }) } />
};
