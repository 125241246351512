export const loginGlobalDMSAPIRequest = {
  scopes: [`openid ` + process.env.REACT_APP_SCOPE]
};
export const loginGlobalAdminDMSAPIRequest = {
  scopes: [`openid ` + process.env.REACT_APP_SCOPE_ADMIN, `openid ` + process.env.REACT_APP_SCOPE]
};
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
  graphMeProfileEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMeManagerEndpoint: "https://graph.microsoft.com/v1.0/me/manager"
};