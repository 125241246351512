import Grid2 from '@mui/material/Unstable_Grid2'
import { Box, Tooltip } from '@mui/material'
import BreadcrumbsView from '../ui-components/BreadcrumbsView'
import ListViewAG from '../ui-components/ListViewAG'
import TreeView from '../ui-components/TreeView'
import FileUpload from '../ui-components/FileUpload'
import UpdateProgress from '../ui-components/UpdateProgress'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { ChosenNode } from "../state/RootStore"
import { useState, useRef, useContext, useEffect } from "react"
import { UserContext } from '../utils/UserContext'
import { SolutionContext } from "../utils/SolutionContext";
import { t } from "../utils/Internationalization"
import { getDocuments } from "../utils/DMSClient"
import { Loading } from './Loading'

export const Home = (): JSX.Element | null => {
  const userInfo = useContext(UserContext);
  const [treeData, setTreeData] = useState([])
  const [continuationToken, setContinuationToken] = useState(null)
  const [chosenNode, setChosenNode] = useState(ChosenNode)
  const [chosenDirectory, setChosenDirectory] = useState()
  const [uploadDrawer, setUploadDrawer] = useState(false)
  const [updateDrawer, setUpdateDrawer] = useState(false)
  const [updateVariables, setUpdateVariables] = useState({}) //State construct is delegated down to child components
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(true);
  const updateOnce = useRef(false)
  const context = useContext(SolutionContext);
  const solutionName = context.SolutionName //Client name against which the corresponding blob and cosmos connections are stored in DMS
  const [cosmosContainerName, setCosmosContainerName] = useState(null);
  const blobContainer = "dms" //Supply the relevant blob container here. This must be in lowercase. It will be created if it doesn't exist
  const calledOnce = useRef(false);//Ensures that the useEffect is called only once

  const setUpdateOnce = (gateOpen: boolean) => {
    updateOnce.current = gateOpen
  }

  //Show Upload option
  const showUpload = () => {
    setUploadDrawer(true)
    setUploading(false)
  }

  const setChosenNodeAndDirectory = (chosenNode: any) => {
    setChosenNode(chosenNode)
    if (chosenNode?.isDirectory || chosenNode === null) {
        setChosenDirectory(chosenNode)
    }
  }

  
  const handleAdd = (id: string, onOpen = () => {/**Do Nothing**/}) => {
    setUpdateVariables({
      action : "ADD",
      message : "Creating new folder ...",
      onOpen: onOpen,
      payload : {
          solutionName: solutionName,
          dmsDocuments: [{
            name: "New Folder",
            parentId: id,
            isDirectory: true,
            properties: {
                lastModifiedOn: new Date().toISOString(),
                lastModifiedBy: `${userInfo.currentUser}`
            }
          }]
        }
      }
    )
    setUpdateOnce(true)
    setUpdateDrawer(true)
  }

  useEffect(() => { 
      setLoading(true)
      getDocuments(solutionName, continuationToken, cosmosContainerName)
      .then((res: any) => {
        setTreeData(res.treeData)
        setLoading(false)
        setContinuationToken(res.continuationToken)
        return null
      })
      .catch((e: any) => {
        setLoading(false)
        setTreeData([])
        console.log("Error getting files and folders: " + e);
      });
    }, [cosmosContainerName])

  if(loading)
  {
    return (<Loading message = 'Getting Files & Folders...'/>);
  }
  return (
    <><Grid2 container justifyContent="center" mt={4} sx={{display: 'flex'}}>
        <Box sx={{width: '30%', height: '100%', overflow: 'auto'}}>
            <TreeView treeData={treeData} setTreeData={setTreeData} chosenNode={chosenNode}  solutionName={solutionName} setChosenNodeAndDirectory={setChosenNodeAndDirectory} 
                      setUpdateVariables={setUpdateVariables} setUpdateDrawer={setUpdateDrawer} updateOnce={updateOnce.current} setUpdateOnce={setUpdateOnce} handleAdd={handleAdd}
                      continuationToken={continuationToken} setContinuationToken={setContinuationToken} cosmosContainerName={cosmosContainerName} setCosmosContainerName={setCosmosContainerName}/>
        </Box>
        <Box sx={{width: '60%', marginLeft: 1}}>
          <Grid2 container direction="row" spacing={2}>
            { userInfo.isEditor && <Grid2><Tooltip title={t('Upload to current location')}>
              <CloudUploadIcon onClick={showUpload}/>
            </Tooltip></Grid2>}
            <Grid2>
                <BreadcrumbsView treeData={treeData} setTreeData={setTreeData} chosenDirectory={chosenDirectory} setChosenNodeAndDirectory={setChosenNodeAndDirectory}/>
            </Grid2>
          </Grid2>
            <Box sx={{overflow: 'auto'}}><ListViewAG treeData={treeData} setTreeData={setTreeData} chosenNode={chosenNode} solutionName={solutionName} setChosenNodeAndDirectory={setChosenNodeAndDirectory} chosenDirectory={chosenDirectory}
                        setUpdateVariables={setUpdateVariables} setUpdateDrawer={setUpdateDrawer} updateOnce={updateOnce.current} setUpdateOnce={setUpdateOnce} handleAdd={handleAdd}/></Box>
        </Box>
    </Grid2>
    <FileUpload uploadDrawer={uploadDrawer} setUploadDrawer={setUploadDrawer} solutionName={solutionName} blobContainer={blobContainer} treeData={treeData} setTreeData={setTreeData}
    uploading={uploading} setUploading={setUploading} chosenDirectory={chosenDirectory}/>
    <UpdateProgress updateVariables={updateVariables} setTreeData={setTreeData} updateDrawer={updateDrawer} setUpdateDrawer={setUpdateDrawer} 
    updateOnce={updateOnce.current} setUpdateOnce={setUpdateOnce}/></>
  )
}

export default Home
