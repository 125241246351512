import { Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { PageLayout } from "./ui-components/PageLayout";
import Home from "./ui-components/Home";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { AuthenticationResult, EventMessage, EventType, InteractionType, NavigationClient, PublicClientApplication } from "@azure/msal-browser";
import { loginGlobalDMSAPIRequest } from "./authConfig";
import { ArcadisThemeProvider } from "./styles/arcadis";
import { ErrorComponent } from "./ui-components/ErrorComponent";
import { GdprProvider } from "@arcadis/gdpr-react";
import { Loading } from "./ui-components/Loading";
import UserContextProvider from "./utils/UserContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { SolutionContext } from "./utils/SolutionContext";
import { Grid, Button, Typography } from "@mui/material";
import { Translate } from './utils/Internationalization';
import config from './state/config.json'
import { ConfigContextProvider } from "./utils/ConfigurationContext";


const LoginLoading = () => {
  return <Loading message="Authentication in progress..." />
}
export let msalInstance: PublicClientApplication;
function App(): JSX.Element {
  let isWhiteListedSolution = true;
  const host = process.env.REACT_APP_HOST ? "https://" + process.env.REACT_APP_HOST + "/" : "http://localhost:7777/"
  const context = useContext(SolutionContext);
  msalInstance = new PublicClientApplication(context.msalConfig);
  if (!config.find(solution => solution.solutionName === context.SolutionName)) {
    isWhiteListedSolution = false;
  }
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const navigationClient = new NavigationClient();
  msalInstance.setNavigationClient(navigationClient);
  const instrumentation_key = process.env.REACT_APP_INTRUMENTATION_KEY as string;
  const redirectUrl = (event: any) => {
    const combinedURL = host + event.target.value;
    location.href = combinedURL;
  };
  return (isWhiteListedSolution ?
    <Router>
      <I18nextProvider i18n={i18n}>
        {/* This is impacting the WAF Policy OWASP3.2 Rule #942260 and #949110. Original package needs to be fixed and brought back. */}
        {/* <GdprProvider instrumentationKey={instrumentation_key}> */}
        <ArcadisThemeProvider>
          <MsalProvider instance={msalInstance}>
            <ConfigContextProvider>
              <UserContextProvider>
                <PageLayout>
                  <Suspense fallback="loading">
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                      authenticationRequest={loginGlobalDMSAPIRequest}
                      errorComponent={ErrorComponent}
                      loadingComponent={LoginLoading}>
                      <Routes>
                        <Route path="/:solution" element={<Home />} />
                      </Routes>
                    </MsalAuthenticationTemplate>
                  </Suspense>
                </PageLayout>
              </UserContextProvider>
            </ConfigContextProvider>
          </MsalProvider>
        </ArcadisThemeProvider>
        {/* </GdprProvider> */}
      </I18nextProvider>
    </Router>
    : <ArcadisThemeProvider>
      <PageLayout>
        <Grid container justifyContent="center">
          <Typography variant="h4" color="grey.800" sx={{ paddingTop: 10 }}>{Translate('Please use the complete URL of the solution where you are registered')}</Typography>
        </Grid>
        <Grid container justifyContent="center" spacing={12} sx={{ paddingTop: 10 }}>
          {config.map((solution) => (
            <Grid key={solution.solutionName} item>
              <Button variant="contained"
                value={solution.solutionName}
                sx={{
                  width: 200, height: 200,
                  backgroundColor: "warning.main",
                  font: "common.black", fontSize: 20
                }}
                onClick={redirectUrl}>
                {solution.solutionTitle}
              </Button>
            </Grid>
          ))}
        </Grid>
      </PageLayout>
    </ArcadisThemeProvider>
  );
}
export default App;
