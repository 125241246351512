import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/material/styles';

export interface LoadingProps {
  message?: string;
  notFound?: boolean;
}
export const Loading = (props: LoadingProps): JSX.Element => {
  const message = props.message || "Loading, please wait...";
  const notFound = props.notFound;
  const theme = useTheme();
  const classes = {
    loadingScreen: {
      color:
        theme.palette.mode === "light" ? theme.palette.secondary.main : "#fff",
      height: "100%"
    },
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={classes.loadingScreen}
    >
      <Grid item>
        {!notFound ? <CircularProgress
          color="inherit"
          aria-labelledby="global-loading-indicator"
        /> : <ErrorIcon />}
      </Grid>
      <Grid item>
        <Typography id="global-loading-indicator">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
