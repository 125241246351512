import { AgGridReact } from 'ag-grid-react';
import { useState, useEffect } from "react";
import { getDescendants } from "./tree";
import { CustomNode } from "./tree/CustomNode";
import { compareItems } from "./tree/utils";
import { parseJSON, compareAsc, isValid } from 'date-fns'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './ListViewAGStyles.css';
import { Translate } from '../utils/Internationalization';

export default function ListViewAG(props: any) {
  //Columnn definition for ag grid
  const columnDefs = [
    { field: 'name', headerName: `${Translate('Name')}`, sortable: true, flex: 1, filter: 'agTextColumnFilter', resizable: true, cellRenderer: getCustomNode },
    { field: 'properties.lastModifiedOn', headerName: `${Translate('Modified')}`, sortable: true, filter: 'agDateColumnFilter', valueFormatter: dateFormatter, comparator: dateComparator, resizable: true },
    { field: 'properties.description', headerName: `${Translate('Description')}`, flex: 1, sortable: true, filter: 'agTextColumnFilter', valueGetter: (params: any) => params.data?.properties?.description, resizable: true, wrapText: true }
  ];
  //Custom file/folder node element reused from tree
  function getCustomNode(params: any) {
    return <CustomNode
      node={params.data}
      listViewMode={true}
      depth={0}
      onSelect={() => props.setChosenNodeAndDirectory(params.data)}
      setTreeData={props.setTreeData}
      treeData={props.treeData}
      solutionName={props.solutionName}
      setUpdateVariables={props.setUpdateVariables}
      setUpdateDrawer={props.setUpdateDrawer}
      updateOnce={props.updateOnce.current}
      setUpdateOnce={props.setUpdateOnce}
      handleAdd={props.handleAdd} />;
  }

  //Date sorting supported here. See relevant locale settings in date-fns
  function dateComparator(date1: any, date2: any) {
    if (date1 === null || date2 === null) {
      return 0;
    }
    return compareAsc(parseJSON(date1), parseJSON(date2));
  }

  //See https://date-fns.org/v2.16.1/docs/parseJSON 
  function dateFormatter(params: any) {
    const formattedDate = parseJSON(params.data?.properties?.lastModifiedOn);
    return (isValid(formattedDate) ? formattedDate.toDateString() : "");
  }

  //This allows us to see sorted folders first and sorts the rest by their name separately
  const defaultSort = (nodes: any) => {
    let directoryNodes = nodes.filter((node: any) => node.isDirectory);
    let nonDirectoryNodes = nodes.filter((node: any) => !node.isDirectory);
    directoryNodes = directoryNodes.sort(compareItems);
    nonDirectoryNodes = nonDirectoryNodes.sort(compareItems);
    return [...directoryNodes, ...nonDirectoryNodes];
  }

  //Prepare the relevant folder content to show in the list view
  const [tableData, setTableData] = useState(defaultSort(getDescendants(props.treeData, "", true)));

  //Load the selected folder content when the folder is chosen any where in the page (list/tree/breadcrumb)
  useEffect(() => {
    console.log("list view AG - useEffect retriggered")
    if (props.chosenDirectory?.id !== undefined) setTableData(defaultSort(getDescendants(props.treeData, props.chosenDirectory?.id, true)))
    if (props.chosenNode === null) setTableData(defaultSort(getDescendants(props.treeData, "", true))) //The root (Home) is in context
  }, [props.chosenNode, props.treeData]);

  return (
    <div className="ag-theme-material">
      <AgGridReact
        rowData={tableData}
        columnDefs={columnDefs}
        domLayout='autoHeight'
        rowHeight={32}
        maxBlocksInCache={1}
      />
    </div>
  );
}
