import React, { useState, createContext } from "react";
import { DragControlState } from "../types";

export const DragControlContext = createContext<DragControlState>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as DragControlState
);

const initialState = {
  isLock: false,
};

export const DragControlProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [isLock, setIsLock] = useState(initialState.isLock);

  return (
    <DragControlContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLock,
        lock: () => setIsLock(true),
        unlock: () => setIsLock(false),
      }}
    >
      {props.children}
    </DragControlContext.Provider>
  );
};
