import React from "react";
import Grid2 from '@mui/material/Unstable_Grid2'
import Box from "@mui/material/Box";
import NavBar from "./NavBar";
import type { SxProps, Theme } from "@mui/material";

export const PageLayout = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const classes : Record<string, SxProps<Theme>> = {
    masterLayout: {
      height: "100vh",
    },
    mainBody: {
      flexGrow: 1,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      overflow: "auto",
    },
    outer: {
      height: "100%",
      width: "100%",
      flexGrow: 1,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
    inner: {
      height: "100%",
      paddingBottom: "3rem",
      width: "100%",
    }
  };

  //const classes = useStyles();
  const {children} = props;
  return (
    <Grid2
      container
      direction="column"
      wrap="nowrap"
      sx={classes.masterLayout}
    >
    <Grid2 sx={{pb:1}}>
        <NavBar />
    </Grid2>
    <Grid2 sx={classes.mainBody}>
        <Box sx={classes.outer}>
          <Box sx={classes.inner}>{children}</Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};
