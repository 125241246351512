import { useState, useEffect } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";

export default function BreadcrumbsView (props: any) {

    //Iterates up the tree to create parent hierarchy
    const getParents = (parentId: string) => {
        const parentFolders: any[] = [];
        const lookup = (parentId: string) => {
            const parent = props.treeData.find((node: any) => node.id === parentId );
            if(parent !== undefined && parentId !== undefined) {
                parentFolders.unshift(
                <Link key={parentId} underline="hover" color="inherit" sx={{cursor: "pointer"}} onClick={() => setChosenNodeAndDirectory(parentId)}>
                  {parent.name}
                </Link>);
                lookup(parent.parentId);
            }
        }
        lookup(parentId);
        return parentFolders;
    }

    //Set chosen node to the one clicked in breadcrumbs
    const setChosenNodeAndDirectory = (nodeId: string) => {
      props.setChosenNodeAndDirectory(props.treeData.find((node: any) => node.id === nodeId))
    }
    
    //Prepare the relevant folder hierarchy to show in the breadcrumbs
    const [parents, setParents] = useState(getParents(props.chosenDirectory?.parentId));

    //Update the parent hierarchy depending upon the refreshed node
    useEffect(() => {
        setParents(getParents(props.chosenDirectory?.parentId))
    }, [props.chosenDirectory, props.treeData]);

    //Home is the root of all content. Hence, few things need to be done by hand
    const setHome = () => {
      props.setChosenNodeAndDirectory(null);//Set the parent ID to root for the list view to show the descendants
      setParents([])
    }

    return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link key={null} underline="hover" color="inherit" sx={{cursor: "pointer"}} onClick={() => setHome()}>
            <Typography>Home</Typography>
          </Link>
          {parents}
          <Typography sx={{fontWeight:'bold'}}>
            {props.chosenDirectory?.name}
          </Typography>
        </Breadcrumbs>
    );
}