import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    partialBundledLanguages: true,
    backend:{
      loadPath: process.env.REACT_APP_I18N_API_URL + `/` + process.env.REACT_APP_CLIENT_ID + `/{{lng}}/namespaces/{{ns}}`,
      queryStringParams: {"code":(process.env.REACT_APP_I18N_API_KEY as string)},
    },
    lng: `${navigator.language}`.slice(0, 2).toLowerCase(),
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged loaded",
      nsMode: "default",
      bindI18nStore: 'added',
      useSuspense: false,
      //wait: true,
    },
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    //t('key'); // -> same as i18next.t
    console.log("i18n initialized now!");
  });

export default i18n;