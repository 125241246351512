import { createContext, useEffect, useContext, useState, useMemo } from 'react';
import { getConfiguration } from './DMSClient';
import { SolutionContext } from './SolutionContext';

export interface  solutionConfiguration{
    solution: string | undefined,
    clientId: string | undefined,
    readerRoles: Array<string> | undefined,
    editorRoles: Array<string> | undefined,
    configurationProperties: any,
    checkUpdate: boolean,
    setCheckUpdate: any
  }

export const ConfigContext = createContext<solutionConfiguration | undefined>({
    solution: "",
    clientId: "",
    readerRoles: [],
    editorRoles: [],
    configurationProperties: {},
    checkUpdate: false,
    setCheckUpdate: () => {return null}
});

export const ConfigContextProvider = (props: any) => {
    const solutionContext = useContext(SolutionContext)
    const [configuration, setConfiguration] = useState<any>({});
    const [checkUpdate, setCheckUpdate] = useState(false);
    const solution = configuration?.solution;
    const clientId = configuration?.clientId;
    const readerRoles = configuration?.configurationProperties?.reader.split(",");
    const editorRoles = configuration?.configurationProperties?.editor.split(",");
    const configurationProperties = configuration?.configurationProperties;
    const value: solutionConfiguration = useMemo((): solutionConfiguration =>  {return {solution, clientId, readerRoles, editorRoles, configurationProperties, checkUpdate, setCheckUpdate}}, [solution, clientId, readerRoles, editorRoles, configurationProperties, checkUpdate, setCheckUpdate]);
    useEffect(() => {
        getConfiguration(solutionContext.SolutionName)
        .then((res) => {
            setConfiguration(res)
          return null;
        })
        .catch(e => {
          console.log(e);
        })}, [checkUpdate]);
    return <ConfigContext.Provider value={value}>
        {props.children}
    </ConfigContext.Provider>
}