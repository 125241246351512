import type { MsalAuthenticationResult } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";

export const ErrorComponent = (
  props: MsalAuthenticationResult
): JSX.Element | null => {
  const { error } = props;
  const { instance } = useMsal();
  if (error) { // If there is an error, logout and redirect to the current URL. 
    //This was found to be a better experience than staying stuck at the msal error while switching apps.
    instance.logoutRedirect()
  }else{
    return null
  }
  return <Box/>
};
