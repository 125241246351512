import { Box, Typography, Button, Drawer, CircularProgress } from '@mui/material';
import { useState, useEffect, useRef, useCallback} from 'react'   
import { insertDocument, patchDocument, getSASForDownload, deleteDocument } from '../utils/DMSClient'
import { t } from '../utils/Internationalization';

//Handles updates to cosmos and allows retry if required
const UpdateProgress = (props: any) : JSX.Element => {
    const [updateFailed, setUpdateFailed] = useState(false)
    const [updated, setUpdated] = useState(false)
    //Removes the relevant nodes from the tree UI
    const deleteFromTree = (descendants: any, notDeleted: any) => {
        //Remove notDeleted descandants from the list of descendants offered for deletion
        const notDeletedIds = notDeleted.map((node: any) => node.id)
        const deletedDescendants = descendants.filter((descendant: any) => {
            return !notDeletedIds.includes(descendant.id)
        })
        const deletedDescendantIds = deletedDescendants.map((node: any) => node.id)
        props.setTreeData((treeData: any) => {
            return treeData.filter((node: any) => {//For every node in the existing state of treeData
                return !deletedDescendantIds.includes(node.id);//Return only those tree nodes that are not in the list of deleted descendants
            })
        })
    }

    //Orchestrates API calls for item updates
    const runActions = useCallback(async () => {
        setUpdateFailed(false) 
        setUpdated(false)
        let result: any = []
        try{
            switch(props.updateVariables.action){
                case "ADD":
                    console.log("UpdateProgress > ADD")
                    result = await insertDocument(props.updateVariables.payload)
                    if(result.length > 0) {//DB call succeeded
                        props.setTreeData((treeData: any) => [
                            ...treeData,                 
                            ...result
                        ])
                        props.updateVariables.onOpen(props.updateVariables.parentId)   //Expand parent folder to show that child is created
                        setUpdated(true)
                        props.setUpdateDrawer(false)
                    } else {//Handle errors here that happen AFTER reaching the service
                        console.log(`API threw an error. Result recieved: ` + result)
                        setUpdateFailed(true)
                    }
                    break
                case "PATCH":
                    console.log("UpdateProgress > PATCH")
                    result = await patchDocument(props.updateVariables.payload)
                    if(result.id !== undefined) {//Patch operation succeeded and we received the updated document
                        props.setTreeData((treeData: any) => {
                            return treeData.map((node: any) => {
                                if (node.id === result.id) {
                                    return {//upsert updated values
                                        ...node,//This lays out all existing properties 
                                        ...props.updateVariables.payload.dmsDocument//This overwrites the overlapping properties with the new values
                                    };
                                }
                                return node;
                            })
                        })
                        setUpdated(true)
                        props.setUpdateDrawer(false)
                    } else {//Handle errors here that happen AFTER reaching the service
                        console.log(`API threw an error. Result recieved: ` + result)
                        setUpdateFailed(true)
                    }
                    break
                case "DOWNLOAD":
                    console.log("UpdateProgress > DOWNLOAD")
                    result = await getSASForDownload(props.updateVariables.solutionName, props.updateVariables.id)
                    if(result.length > 0) {//SAS call succeeded
                        //const url = URL.createObjectURL(result)
                        window.open(result)
                        setUpdated(true)
                        props.setUpdateDrawer(false)
                    } else {//Handle errors here that happen AFTER reaching the service
                        console.log(`API threw an error. Result recieved: ` + result)
                        setUpdateFailed(true)
                    }
                    break
                case "DELETE":
                    console.log("UpdateProgress > DELETE")
                    //A list of descendants is an ordered list of children, grandchildren, etc.
                    result = await deleteDocument(props.updateVariables.payload)
                    if(result.documentsNotDeleted !== undefined) {//Returns the array of descendants that were not deleted (if any)
                        deleteFromTree(props.updateVariables.payload.dmsDocuments, result.documentsNotDeleted)
                        setUpdated(true)
                        props.setUpdateDrawer(false)
                    } else {//Handle errors here that happen AFTER reaching the service
                        console.log(`API threw an error. Result recieved: ` + result)
                        setUpdateFailed(true)
                    }
                    break
                default:

            }
            
        } catch (error) {//Handle errors here that happen BEFORE reaching the service 
            console.log(`API threw an error. Error: ` + error)
            setUpdateFailed(true)
        }  
    },[props])

    useEffect(() => {
        if (props.updateVariables.action !== undefined && props.updateOnce) {
            console.log("updateProgress > useEffect")
            props.setUpdateOnce(false) 
            runActions()
        }
    },[props])

    return (
        <Drawer open={props.updateDrawer} anchor='bottom' sx={{m:2}}> 
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', m:2 }}>
                
                {(updated || updateFailed) ? 
                        (updateFailed && 
                                (<><Typography sx={{ m: 2 }} variant="h6">{t('Something went wrong. A retry might help.')}</Typography>
                                <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <Button sx={{ margin: 2 }} onClick={() => props.setUpdateDrawer(false)}>{t('Cancel')}</Button>
                                    <Button sx={{ margin: 2 }} variant="contained" onClick={runActions}>{t('Retry')}</Button>
                                </Box></>))
                    : (<><Typography sx={{ m: 2 }} variant="h6">{props.updateVariables.message}</Typography><CircularProgress sx={{ m: 2 }}/></>)
                }
            </Box>
        </Drawer>
    )

};

export default UpdateProgress;