import { RequestPolicyOptionsLike, WebResourceLike } from "@azure/core-http";
import { BlockBlobClient, AnonymousCredential, BaseRequestPolicy, newPipeline, HttpOperationResponse } from "@azure/storage-blob";
import { getSASForUpload } from "./DMSClient";

  //Makes the file name part of the path with SAS
  function insert(main_string: string, ins_string: string) {
    const pos = main_string.indexOf('?');
    return main_string.slice(0, pos) + '/' + 
            encodeURIComponent(decodeURIComponent(ins_string)) + //Prevent double encoding
            main_string.slice(pos);
  }

  class SasStore {
    private sasCache = {'fullSASUrl':""}; 
    private solution;
    private container;
    public constructor(solution:string, container: string) {
        this.solution = solution;
        this.container = container;
    }

    // Get a valid SAS for blob
    public async getValidSASForBlob (fileName:string) {
        if (this.sasCache['fullSASUrl'] && this.isSasStillValidInNext2Mins(this.sasCache['fullSASUrl'])) {
          console.log(`fullSASUrl found to be still valid: ` + this.sasCache['fullSASUrl'])
            return this.sasCache['fullSASUrl'];
        } else {
            this.sasCache['fullSASUrl'] = insert(await getSASForUpload(this.solution, this.container), fileName);
            console.log(`fullSASUrl cached as: ` + this.sasCache['fullSASUrl'])
            return this.sasCache['fullSASUrl'];
        }
    }

    // Return true if "se" section in SAS is still valid in next 2 mins
    private isSasStillValidInNext2Mins (sas: string) {
        const expiryStringInSas = new URL(sas).searchParams.get("se") || "2099-03-15T00:47:38.813Z";//The se parameter must be in the SAS section. Otherwise, we default to a distant future and avoid going into the loop of querying refreshes
        return new Date(expiryStringInSas).valueOf() - new Date().valueOf() >= 2 * 60 * 1000;
    }    
  }
  
  class SasUpdatePolicyFactory {
    private sasStore;
    private fileName;
    public constructor(sasStore: SasStore, fileName: string) {
      this.sasStore = sasStore;
      this.fileName = fileName;
    }
    public create(nextPolicy: any, options: any) {
      return new SasUpdatePolicy(nextPolicy, options, this.sasStore, this.fileName);
    }
  }
  
  class SasUpdatePolicy extends BaseRequestPolicy {

    private sasStore;
    private fileName;
    // eslint-disable-next-line max-params
    public constructor(nextPolicy: any, options: RequestPolicyOptionsLike, sasStore: SasStore, fileName: string) {
      super(nextPolicy, options);
      this.sasStore = sasStore;
      this.fileName = fileName;
    }
  

    public async sendRequest(webResource: WebResourceLike): Promise<HttpOperationResponse> {
      const urlObj = new URL(webResource.url);
      const sas = await this.sasStore.getValidSASForBlob(this.fileName);//Intercept here to add the File Name to the URL
      new URL(sas).searchParams.forEach((value, key) => {
        urlObj.searchParams.set(key, value);
      });
  
      // Update request URL with latest SAS
      webResource.url = urlObj.toString();
  
      return this._nextPolicy.sendRequest(webResource);
    }
  }

  export async function getUploadClient(solution: string, container: string, fileName: string){
    const sasStore = new SasStore(solution, container);
  
    const pipeline = newPipeline(new AnonymousCredential());
    // Inject SAS update policy factory into current pipeline
    pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore, fileName));
  
    return new BlockBlobClient(
      `${await sasStore.getValidSASForBlob(fileName)}`,
      pipeline
    );
  }
  