import { msalInstance } from "../App";

export async function GetToken(loginRequest:{ scopes: string[]; }) {
  
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    return null;
  }
  const handleLogin = async () => {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response?.accessToken;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  const accessToken =await handleLogin();
  return accessToken!;// will into it later
}
