import { useMsal } from "@azure/msal-react";
import { createContext, useContext, useMemo } from "react";
import { ConfigContext } from './ConfigurationContext';

export interface userInfo {
    isEditor: boolean,
    isContributor: boolean,
    isReader: boolean,
    currentUser: string | undefined,
    currentUserMail: string | undefined
  }

export const UserContext = createContext<userInfo>({
                                                    isEditor: false,
                                                    isContributor: false,
                                                    isReader: true,
                                                    currentUser: "",
                                                    currentUserMail: ""
                                                });

export const UserContextProvider = (props: any) => {
    const { instance } = useMsal();
    const configContext = useContext(ConfigContext);
    const currentAccount = instance.getActiveAccount();
    const currentUserAvailableRoles = currentAccount?.idTokenClaims?.roles;
    const isEditor: boolean = (configContext?.editorRoles?.some(role => currentUserAvailableRoles?.includes(role))) ?? false;
    const isContributor = false;
    const isReader: boolean = (configContext?.readerRoles?.some(role => currentUserAvailableRoles?.includes(role))) ?? false;
    const currentUser = currentAccount?.["name"]?.replace(",", "");
    const currentUserMail = currentAccount?.["username"];
    const value: userInfo = useMemo((): userInfo =>  {return {isEditor, isContributor, isReader, currentUser, currentUserMail}}, [isEditor, isContributor, isReader, currentUserMail, currentUser]);
    return (<UserContext.Provider value={value}>
                {props.children}
            </UserContext.Provider>
        );
};

export default UserContextProvider;
