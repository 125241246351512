
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {DisplayName, Profile, AdminSettings} from "./Profile";
import { useContext } from "react";
import { UserContext } from '../utils/UserContext'
import { useIsAuthenticated } from "@azure/msal-react";
import { Grid, Stack } from '@mui/material';
const NavBar = (): JSX.Element => {
  const userInfo = useContext(UserContext);
  const isAuthenticated = useIsAuthenticated();
  return (
    <AppBar sx={{backgroundColor:"#fff"}} position="static">
      <Toolbar>
      <Grid container direction="row" alignItems="center">
        <Stack direction="row" spacing={55}><Typography>
          <img
            src="/logo_small.jpg"
            alt="logo"
          />
        </Typography>
        <Typography variant="h4" sx={{ color:"heritage.main", margin:"auto"}}>Documents</Typography>
        </Stack>
        <DisplayName />
        <Profile />
        {isAuthenticated && userInfo.isEditor && <AdminSettings/>}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
