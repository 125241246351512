import React from 'react';
import App from "./App";
import { createRoot } from "react-dom/client";
import { SolutionContextProvider } from './utils/SolutionContext';

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <SolutionContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SolutionContextProvider>
  );
} else {
  console.error("Unable to find React root element: #root");
}

