import axios from "axios";
import { msalInstance } from "../App";
import { loginGlobalDMSAPIRequest } from "../authConfig";
import { GetToken } from "./GetToken";

// export interface FolderStruct{
//                                 documentRequest: {
//                                     SolutionName: string,
//                                     DMSDocuments: {
//                                         id: string,
//                                         parentId: string,
//                                         name: string,
//                                         fileStorageContainer: string,
//                                         fileStorageDirectory: string,
//                                         version: string,
//                                         isDirectory: string,
//                                         properties: {
//                                             uploadedOn: string,
//                                             uploadedBy: string,
//                                             lastModified: string,
//                                             size: string,
//                                             type: string,
//                                             originalName: string,
//                                             lastModifiedOn: string,
//                                             lastModifiedBy: string
//                                         },
//                                         tags: any
//                                     },
//                                 }
//                             }

export interface Document{
    id: string,
    parentId: string,
    name: string,
    fileStorageName: string | "",
    shortDescription: string | "",
    fileStorageContainer: string | "",
    fileStorageDirectory: string | "",
    version: string | "",
    isDirectory: boolean | false,
    properties: any,
    tags: any
}

export const axiosClient = async () => {
    const accessToken = await GetToken(loginGlobalDMSAPIRequest);
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: { 'Authorization': `Bearer ${accessToken}` }
    });
}
export const axiosClientI18N = () => {
    const currentAccount = msalInstance.getActiveAccount();
    const idToken = currentAccount?.idToken;
    return axios.create({
        headers: {
            "authorization": `Bearer ${idToken}`,
            "Content-type": "application/json"
        },
    });
}
export async function searchDocument(solution: string, searchText: string, tag: string) {
    const client = await axiosClient();
    const config = {
        params: {
            searchText: searchText,
            solution: solution,
            tag: tag
        }
    };
    // DMS endpoint
    const response = await client.get(`/SearchDocument`, config);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}

export async function getDocuments(solution: string, continuationToken: string | null, cosmosContainerName?: string | null) {
    const accessToken = await GetToken(loginGlobalDMSAPIRequest);
    const client = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'x-ms-continuation': continuationToken,
            'Authorization': `Bearer ${accessToken}`
        }
    });
    const config = {
        params: {
            solution: solution,
            cosmosContainerName: cosmosContainerName
        }
    };
    const response = await client.get(`/GetDocuments`, config);
    if (response.status === 200) {
        return { treeData: response.data, continuationToken: response.headers['x-ms-continuation'] };
    } else {
        return [];
    }
}

export async function insertDocument(documentBody: any) {
    const client = await axiosClient();
    console.log("insertDocument client uri:" + client.getUri())
    const requestBody = {
        "saveDocumentsRequest": {
            ...documentBody
        }
    }
    const response = await client.post(`/InsertDocuments`, requestBody);
    if (response.status === 200) {
        return response.data.map((doc: Document) => {return {id: doc.id, name: doc.name, parentId: doc.parentId, isDirectory: doc.isDirectory, fileStorageContainer: doc.fileStorageContainer, fileStorageDirectory: doc.fileStorageDirectory, fileStorageName: doc.fileStorageName, version: doc.version, properties: doc.properties, tags: doc.tags, shortDescription: doc.shortDescription}})
    } else {
        return [];
    }
}

export async function patchDocument(documentBody: any) {
    const client = await axiosClient();
    console.log("patchDocument client uri:" + client.getUri())
    const requestBody = {
        "updateDocumentRequest": {
            ...documentBody
        }
    }
    const response = await client.post(`/PatchDocument`, requestBody);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}


export async function deleteDocument(documentBody: any) {
    const client = await axiosClient();
    const requestBody = {
        "deleteDocumentsRequest": {
            ...documentBody
        }
    }
    const response = await client.post(`/DeleteDocuments`, requestBody);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}


export async function getSASForUpload(solution: string, container: string) {
    const client = await axiosClient();
    const config = {
        params: {
            solution: solution,
            container: container
        }
    };
    const response = await client.get(`/GetUploadContainerSasUrl`, config);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}

export async function getSASForDownload(solution: string, id: string) {
    const client = await axiosClient();
    const config = {
        params: {
            solution: solution,
            id: id
        }
    };
    const response = await client.get(`/GetDownloadDocumentUri`, config);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}
export async function getSASForFolderDownload(solution: string, filesMetaData: any) {
    const client = await axiosClient();
    const data = {
        documentRequest: {
            SolutionName: solution,
            DMSDocuments: filesMetaData
        }
    }
    const response = await client.post(`/GetDirectoryFilesUri`, data);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}
export async function getConfiguration(solution: string) {
    const client = await axiosClient();
    const config = {
        params: {
            solution: solution
        }
    };
    const response = await client.get(`/GetConfiguration`, config);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}
export async function patchConfiguration(body: any) {
    const client = await axiosClient();
    const data = {
        patchConfigurationRequest: body
    };
    const response = await client.patch(`/PatchConfiguration`, data);
    if (response.status === 200) {
        return response.data;
    } else {
        return [];
    }
}

export async function getApplicationRoles() {
    const client = await axiosClient();
    const response = await client.get(`/GetAppRoles`);
    if (response.status === 200) {
        return response.data[0].appRoles;
    } else {
        return [];
    }
}
