import { createContext, useMemo } from "react";
import config from '../state/config.json'
export interface Config{
  SolutionName: string
  msalConfig: {
    auth: {
        clientId: string;
        authority: string;
        knownAuthorities: string[];
        redirectUri: string;
        postLogoutRedirectUri: string;
        authorityMetadata: string;
    };
    cache: {
        cacheLocation: string;
        storeAuthStateInCookie: boolean;
        secureCookies: boolean;
    };
  }
}
export const SolutionContext = createContext<Config>({
                                                      SolutionName: "",
                                                      msalConfig: {
                                                        auth: {
                                                            clientId: "",
                                                            authority: "",
                                                            knownAuthorities: [],
                                                            redirectUri: "",
                                                            postLogoutRedirectUri: "",
                                                            authorityMetadata: "",
                                                        },
                                                        cache: {
                                                            cacheLocation: "",
                                                            storeAuthStateInCookie: false,
                                                            secureCookies: false
                                                        },
                                                      }
                                                    })
export const SolutionContextProvider = (props: any) => {
  const host = process.env.REACT_APP_HOST || "localhost:7777" as string;
  const schema = host.startsWith("localhost") ? "http" : "https";
  const signin_signup = process.env.REACT_APP_SIGN_UP_SIGN_IN_POLICY as string;
  const tenant = process.env.REACT_APP_TENANT_NAME as string;
  const solutionName = window.location.href.split("/")[3]?.toUpperCase() || "" ;
  const solutionConfig = config.find((solution: any) => solution.solutionName === solutionName)
  const clientIdKey = "clientId-" + process.env.REACT_APP_ENV
  const solutionClientId = solutionConfig ? solutionConfig[clientIdKey as keyof typeof solutionConfig] : ""
  const value = useMemo((): Config =>  {return {SolutionName: solutionName, msalConfig: {
    auth: {
      clientId: solutionClientId as string ,
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${signin_signup}`,
      knownAuthorities: [`${tenant}.b2clogin.com`],
      redirectUri: `${schema}://${host}/${solutionName}/oauth-callback`,
      postLogoutRedirectUri: `${schema}://${host}/${solutionName}`,
      authorityMetadata: process.env.REACT_APP_B2C_METADATA_PULLED as string || process.env.REACT_APP_B2C_METADATA as string
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
      secureCookies: false
  },
  }}}, []);
  return(
    <SolutionContext.Provider value = {value}>
      {props.children}
    </SolutionContext.Provider>
  )
};  