/* eslint-disable react/no-unstable-nested-components */
import { Chip, CssBaseline, MenuItem, Select, Typography, InputLabel, FormControl, OutlinedInput } from '@mui/material';
import { Tree, MultiBackend, getBackendOptions, DropOptions, TreeMethods } from "./tree";
import { DndProvider } from "react-dnd";
import { CustomNode } from "./tree/CustomNode";
import { CustomDragPreview } from "./tree/CustomDragPreview";
import { UserContext } from "../utils/UserContext";
import { useState, useContext, useRef } from "react";
import IconButton from '@mui/material/IconButton';
import AddIcon from "@mui/icons-material/Add";
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import { getDocuments } from "../utils/DMSClient"
import { SolutionContext } from "../utils/SolutionContext";

export default function TreeView(props: any) {
  const userInfo = useContext(UserContext);
  const context = useContext(SolutionContext);
  const solutionName = context.SolutionName
  const handleDrop = (newTree: any, options: DropOptions) => {
    props.setUpdateVariables({
        action : "PATCH",
        message : "Updating ...",
        payload : {
        solutionName: props.solutionName,
        dmsDocument: {
          id: options.dragSourceId,
          parentId: options.dropTargetId,
          properties: {
              lastModifiedOn: new Date().toISOString(),
              lastModifiedBy: `${userInfo.currentUser}`
            }
          }
        }
      }
    )
    props.setUpdateOnce(true)
    props.setUpdateDrawer(true)
  };

  const canDrag = (node: any) => {
    return userInfo.isEditor ? true : false;
  };
  const refTreeView = useRef<TreeMethods>(null);
  //const handleOpenAll = () => {useOpenIdsHelper.handleOpenAll()};
  const [allOpened, setAllOpened] = useState(false);
  const OpenAll = () => {
    if(refTreeView?.current){
      refTreeView.current.openAll()
      setAllOpened(true)
    }}
  const CloseAll = () => {
    if(refTreeView?.current ) {
      refTreeView.current.closeAll()
      setAllOpened(false)
    }}
  const getMoreDocuments = () => {
    getDocuments(props.solutionName, props.continuationToken, props.cosmosContainerName)
      .then((res: any) => {
        props.setTreeData((treeData: any) => [...treeData, ...res.treeData])
        props.setContinuationToken(res.continuationToken)
        return null
      })
      .catch((e: any) => {
        console.log("Error getting files and folders: " + e);
      });
  }
  const handleChange = (event: any) => {
    props.setCosmosContainerName(event.target.value as string);
  }

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Stack direction="row" spacing={1} alignItems="right">
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-simple-select-filled-label">Select Container Here</InputLabel>
        <Select
          value={props.cosmosContainerName}
          onChange={handleChange}
          size="small"
          variant="standard"
          autoWidth
        >
          <MenuItem value="Documents">Project1</MenuItem>
          <MenuItem value="Documents2">Project2</MenuItem>
          <MenuItem value="Documents3">Project3</MenuItem>
        </Select>
      </FormControl>
      </Stack>
    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
      {allOpened ? <Tooltip title="Collapse All"><IconButton onClick={CloseAll}><KeyboardArrowDownIcon /></IconButton></Tooltip>
          : <Tooltip title="Expand All"><IconButton onClick={OpenAll}><KeyboardArrowRightIcon /></IconButton></Tooltip> }
      {userInfo.isEditor && <Tooltip title="Create folder"><IconButton onClick={() => props.handleAdd("")}><AddIcon /></IconButton></Tooltip>}
      <Typography variant="subtitle2" sx={{ color:"heritage.main", margin:"auto"}}>{`${props.treeData.length}`} Files/Folders </Typography>
      {props.continuationToken && <Chip label="Load More" onClick={getMoreDocuments}/>}
    </Stack>
        <CssBaseline />
          <Tree
            ref={refTreeView}
            tree={props.treeData}
            rootId=""
            render={(node, { depth, isOpen, onToggle, onOpen }) => (
              <CustomNode
                node={node}
                depth={depth}
                solutionName={props.solutionName}
                isOpen={isOpen}
                isSelected={node.id === props.chosenNode?.id}
                onToggle={onToggle}
                onOpen={onOpen}
                onSelect={() => props.setChosenNodeAndDirectory(node)}
                setTreeData={props.setTreeData}
                treeData={props.treeData}
                setUpdateVariables={props.setUpdateVariables}
                setUpdateDrawer={props.setUpdateDrawer}
                updateOnce={props.updateOnce.current} 
                setUpdateOnce={props.setUpdateOnce}
                handleAdd={props.handleAdd}
              />
            )}
            dragPreviewRender={(monitorProps) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            onDrop={handleDrop}
            canDrag = {canDrag}
          />
      </DndProvider>
  );
}
