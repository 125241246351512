import { NodeModel } from "../types";

export const getDescendants = <T = unknown>(
  treeData: NodeModel<T>[],
  id: NodeModel["id"],
  onlyOneLevel?: boolean
): NodeModel<T>[] => {
  let descendants: NodeModel<T>[] = [];

  const search = (tree: NodeModel<T>[], ids: NodeModel["id"][]) => {
    const children = tree.filter((node) => ids.includes(node.parentId));

    if (children.length > 0) {
      descendants = [...descendants, ...children];

      if(!onlyOneLevel){
        search(
          tree,
          children.map((node) => node.id)
        );
      }
    }
  };

  search(treeData, [id]);

  return descendants;
};
