import i18n from "../i18n";
import { useTranslation } from "react-i18next";
//Required due to translations being available later for some views.
export function Translate(value: string) {
    const { t } = useTranslation();
    return t(value);
}
export function t(value: string)
{
    return i18n.t(value);
}