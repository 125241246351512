import { useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider, StyledEngineProvider, Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import arcadisPalette, { coreColors } from "./arcadis/palette";
import arcadisTypography from "./arcadis/typography";


const useArcadisTheme = (allowDark: boolean): Theme => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  return useMemo(
    () =>
      createTheme({
        palette: {
          mode: allowDark && prefersDarkMode ? "dark" : "light",
          ...arcadisPalette,
          background: {
            default: "#fafafa"
          }
        },
        typography: arcadisTypography,
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        components: {
          MuiListItem: {
            styleOverrides: {
              secondaryAction: {
                backgroundColor: coreColors.wisdom.main,
                "&:hover": {
                  backgroundColor: coreColors.sustainability.T25,
                },
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
              },
            },
          },
        }
      }),
    [allowDark, prefersDarkMode]
  );
};

export interface ArcadisThemeProviderProps {
  children?: React.ReactNode;
  allowDark?: boolean;
}

export function ArcadisThemeProvider({
  children,
  allowDark,
}: ArcadisThemeProviderProps): JSX.Element {
  const theme = useArcadisTheme(typeof allowDark === "boolean" ? allowDark : false);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
