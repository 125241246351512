/* eslint-disable react/prop-types */
import { TypeIcon } from "./TypeIcon";
import styles from "./CustomDragPreview.module.css";

//This controls how the item looks while being dragged
export const CustomDragPreview = (props) => {

  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <TypeIcon isDirectory={item.isDirectory} name={item.name} />
      </div>
      <div className={styles.label}>{item.name}</div>
    </div>
  );
};
