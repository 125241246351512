import { PaletteOptions, PaletteColor, SimplePaletteColorOptions } from '@mui/material/styles';
import { red } from '@mui/material/colors';

type ArcadisPaletteColor = PaletteColor & {
  T10?: string;
  T25?: string;
  T50?: string;
  T75?: string;
};

type ArcadisPaletteColorOptions = SimplePaletteColorOptions & {
  T10?: string;
  T25?: string;
  T50?: string;
  T75?: string;
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    heritage: ArcadisPaletteColor;
    sustainability: ArcadisPaletteColor;
    confidence: ArcadisPaletteColor;
    wisdom: PaletteColor;
    integrity: ArcadisPaletteColor;
  }
  interface PaletteOptions {
    heritage: ArcadisPaletteColorOptions;
    sustainability: ArcadisPaletteColorOptions;
    confidence: ArcadisPaletteColorOptions;
    wisdom: SimplePaletteColorOptions;
    integrity: ArcadisPaletteColorOptions;
  }
}

export const coreColors: PaletteOptions = {
  heritage: {
    main: "#E4610F",
    light: "#E4610F",
    T75: "#EB894B",
    T50: "#F2B087",
    T25: "#F8D8C3",
    T10: "rgba(228, 97, 15, 0.08)"
  },
  sustainability: {
    main: "#007377",
    light: "#007377",
    T75: "#409699",
    T50: "#80B9BB",
    T25: "#BFDCDD"
  },
  wisdom: {
    main: "#ffffff",
    light: "#ffffff",
    dark: "#0C0808",
  },
  confidence: {
    main: "#0C0808",
    light: "#0C0808",
    dark: "#ffffff",
    T75: "#494646",
    T50: "#868484",
    T25: "#C2C1C1",
    T10: "#E7E6E6",
  },
  integrity: {
    main: "#326295",
    light: "#326295",
    dark: "#326295",
    T75: "#6589B0",
    T50: "#99B1CA",
    T25: "#CCD8E5",
  }
};

const arcadisPalette: PaletteOptions = {
  ...coreColors,
  primary: coreColors.heritage,
  secondary: coreColors.sustainability,
  error: {
    main: red.A400,
  },
  text: {
    primary: coreColors.confidence.main,
    secondary: coreColors.confidence.T50
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

export default arcadisPalette;
