//TODO: Enable es-lint back
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArrowRight, Delete } from "@mui/icons-material";
import { useDragOver } from "./hooks/useDragOver";
import { TypeIcon } from "./TypeIcon";
import styles from "./CustomNode.module.css";
import { getDescendants } from "./utils/getDescendants";
import { Box } from "@mui/system";
import { UserContext } from "../../utils/UserContext";
import { t } from "../../utils/Internationalization";
import DownloadIcon from '@mui/icons-material/Download';
import { getSASForFolderDownload } from "../../utils/DMSClient";
import { Button ,Drawer, Grid} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import { AgGridReact } from 'ag-grid-react';
import config from '../../state/config.json';
//A node component that can be dragged and dropped. The intent is to make it reusable outside the treeview as well.
export const CustomNode = (props) => {
  const { id, name, isDirectory } = props.node;
  const [hover, setHover] = useState(false);
  const [folderOpen, setFolderOpen] = useState(false);
  const [visibleInput, setVisibleInput] = useState(false);
  const [labelText, setLabelText] = useState(name);
  const indent = props.depth * 24;
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const userInfo = useContext(UserContext);
  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
    setFolderOpen(true);
  };
  const handleShowInput = () => {
    setLabelText(props.node.name);
    setVisibleInput(true);
  };
  const handleCancel = () => {
    setVisibleInput(false);
  };
  const handleChangeText = (e) => {
    setLabelText(e.target.value);
  };
  const handleClick = (event) => {
    event.preventDefault();
		event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setVisibleMenu(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setVisibleMenu(false);
  };
  const handleDownload = async () => {
    console.log("download clicked");
    if(!props.node.isDirectory)
    {
      handleSelect();
    }
    if(props.node.isDirectory)
    {
      let listOfFilesMetaData = getDescendants(props.treeData, id, false);
      const parentData = props.treeData.find(x => x.id === id)
      listOfFilesMetaData.push(parentData);
      setShowProgress(true);
      getSASForFolderDownload(props.solutionName, listOfFilesMetaData)
      .then((res) => {
        
          setShowProgress(false);
          window.open(res);
        return null;
      })
      .catch((e) => {
        setDownloadFailed(true);
        console.log("Download has failed.");
        console.log(e)
      })
    }
  };
  const handleProperties = () => {
    setShowProperties(true);
  };
  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);
  const handleSelect = () => {
    props.onSelect(props.node)
    if (!props.node.isDirectory){
      props.setUpdateVariables({
          action : "DOWNLOAD",
          message : "Initiating download ...",
          solutionName: props.solutionName,
          id: id
        }
      )
      props.setUpdateOnce(true)
      props.setUpdateDrawer(true)
    }
  }

  const handleDelete = (id) => {
    props.setUpdateVariables({
        action : "DELETE",
        message : "Attempting deletion...",
        id: id,
        payload : {
          solutionName: props.solutionName,
          //We send a reverse ordered list of items to be deleted. This allows for deletion from tail first and preserves the hierarchy. 
          //We stop deleting from this list on failure eg: if one blob is say locked from deletion.
          //The API will return the remaining items that couldn't be deleted.
          dmsDocuments: [props.node, ...getDescendants(props.treeData, id, false)].reverse()
        }
      }
    )
    props.setUpdateOnce(true)
    props.setUpdateDrawer(true)
  }

  const handleAdd = () => {
    props.setUpdateVariables({
      action : "ADD",
      message : "Creating new folder ...",
      parentId: id,
      onOpen: props.onOpen,
      payload : {
          solutionName: props.solutionName,
          dmsDocuments: [{
            name: "New Folder",
            parentId: id,
            isDirectory: true,
            properties: {
                lastModifiedOn: new Date().toUTCString(),
                lastModifiedBy: `${userInfo.currentUser}`
            }
          }]
        }
      }
    )
    props.setUpdateOnce(true)
    props.setUpdateDrawer(true)
  }

  const handleRename = () => {
    props.node.properties["lastModifiedOn"] = new Date().toUTCString();
    props.node.properties["lastModifiedBy"] = userInfo.currentUser;
    props.setUpdateVariables({
      action : "PATCH",
      message : "Updating ...",
      payload : {
          solutionName: props.solutionName,
          dmsDocument: {
            id: id,
            name: labelText,
            properties: props.node.properties
          }
        }
      }
    )
    props.setUpdateOnce(true)
    props.setUpdateDrawer(true)
    setVisibleInput(false)
  }

  return (
    <Box
      className={`tree-node ${styles.root} `}
      style={{paddingInlineStart: indent}}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}// This event may not trigger on fast mouse moves. CSS covers for this.
    >
      <ProgressDrawer showProgress = {showProgress}
                      setShowProgress = {setShowProgress}
                      downloadFailed = {downloadFailed}
                      setDownloadFailed = {setDownloadFailed}/>
      <PropertiesDrawer showProperties={showProperties} 
                        setShowProperties = {setShowProperties} 
                        props = {props}/>
      {!props.listViewMode && <div className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""}`}>
        {props.node.isDirectory && (
          <div onClick={handleToggle}>
            <ArrowRight />
          </div>
        )}
      </div>}
      <Box sx={{display: 'flex'}}>
        <TypeIcon isDirectory={isDirectory} name={name} folderOpen={folderOpen}/>
      </Box>

      {visibleInput ? ( //Show the text field with save/edit
        <>
          <div className={styles.labelGridItem}>
              <Input fontSize="small" margin="dense" value={labelText} onChange={handleChangeText}/>
          </div>
          <IconButton size="small" onClick={handleRename} disabled={labelText === ""}>
            <CheckIcon fontSize="small" className={styles.editIcon} />
          </IconButton>
          <IconButton size="small" onClick={handleCancel}>
            <CloseIcon fontSize="small" className={styles.editIcon} />
          </IconButton>
        </>
      ) : (hover ? (//Show plain text with options on hover
        <div className={styles.itemWithOptions}>
          <Box className={styles.labelGridItem} sx={[props.isSelected && {bgcolor:'heritage.T10'}]} onClick={handleSelect} onContextMenu={handleClick}>
            <Typography variant="body2">
              {props.node.name}
            </Typography>
          </Box>
          <CustomMenuItems userInfo = {userInfo} handleAdd = {props.handleAdd} onOpen={props.onOpen}
                           handleClose = {handleClose} handleShowInput = {handleShowInput} 
                           handleDelete = {handleDelete} handleClick = {handleClick}
                           anchorEl = {anchorEl} visibleMenu = {visibleMenu}
                           node = {props.node} id={id} handleDownload = {handleDownload}
                           handleProperties = {handleProperties}

          />
        </div>
      ) : ( //Just show the name
        <Box className={styles.labelGridItem} sx={[props.isSelected && {bgcolor:'heritage.T10'}]} onClick={handleSelect}> 
          <Typography variant="body2" className={styles.nodeLabel}>
            {props.node.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const CustomMenuItems = (props) => {
  // console.log("menu items called");
  return (
          <MenuItems userInfo = {props.userInfo}
                     anchorEl={props.anchorEl}
                     visibleMenu = {props.visibleMenu}
                     handleClose = {props.handleClose}
                     node = {props.node}
                     handleShowInput = {props.handleShowInput}
                     handleAdd = {props.handleAdd}
                     onOpen={props.onOpen}
                     id={props.id}
                     handleDelete = {props.handleDelete}
                     handleClick = {props.handleClick}
                     handleDownload = {props.handleDownload}
                     handleProperties = {props.handleProperties}/>
      );

};

const MenuItems = (props) => {
  let menuItems;
  switch(true){
    case props.userInfo.isEditor === true:
      menuItems = (<span className={styles.moreIcon}>
                        <IconButton size="small" onClick={props.handleClick}>
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                        <Menu id="long-menu" anchorEl={props.anchorEl} open={props.visibleMenu} onClose={props.handleClose}>
                        <MenuItem onClick={() => { props.handleDelete(props.node.id); props.handleClose() }}>
                          <Delete fontSize="small" /><Typography sx={{ ml: 1 }} variant="body2">{t('Delete')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { props.handleShowInput(); props.handleClose() }}>
                          <EditIcon fontSize="small" /><Typography sx={{ ml: 1 }} variant="body2">{t('Rename')}</Typography>
                        </MenuItem>
                        {props.node.isDirectory && 
                          <MenuItem onClick={() => { props.handleAdd(props.id, props.onOpen); props.handleClose()}}>
                          <AddIcon fontSize="small" /><Typography sx={{ ml: 1 }} variant="body2">{t('Add Child Folder')}</Typography>
                          </MenuItem>
                        }
                        <MenuItem onClick={() => { props.handleDownload(); props.handleClose() }}>
                          <DownloadIcon fontSize="small" /><Typography sx={{ ml: 1 }} variant="body2">{t('Download')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { props.handleProperties(); props.handleClose() }}>
                          <InfoIcon fontSize="small" /><Typography sx={{ ml: 1 }} variant="body2">{t('Properties')}</Typography>
                        </MenuItem>
                      </Menu>
                  </span>);
          break
    case props.userInfo.isReader === true:
          // do nothing, few other options will come here
          break
  }
  return menuItems;
};

const FileProperty = ({propertyValue, propertyName, props}) => {
  const indent = 0 * 24;
  const [mouseOver, setMouseOver] = useState(false);
  const [inputText, setInputText] = useState("");
  const [visible, setVisible] = useState(false);
  let value = propertyValue;
  const handleClick = () => {
    setInputText(propertyValue);
    setVisible(true);
  }
  const handleChangeText = (event) => {
    setInputText(event.target.value);
  }
  const handleCancel = () => {
    setInputText(propertyValue);
    setVisible(false);
  }
  function dateFormatter(propertyValue){
    //const formattedDate = new Date(parseInt(propertyValue, 10));
    return (propertyValue);
  }
  function sizeFormatter(propertyValue){
    const value = parseInt(propertyValue, 10);
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let unitCount = 0
    let size = parseInt(value, 10) || 0;
    while(size >= 1024 && ++unitCount){
      size = size/1024;
    }
    return(size.toFixed(size < 10 && unitCount > 0 ? 1 : 0) + ' ' + units[unitCount]);
  }
  const handleUpdateProperties = (e) => {
    props.node.properties[`${propertyName}`] = inputText;
    props.setUpdateVariables({
      action : "PATCH",
      message : "Updating ...",
      payload : {
          solutionName: props.solutionName,
          dmsDocument: {
            id: props.node.id,
            name: props.node.name,
            properties: props.node.properties
          }
        }
      }
    );
    props.setUpdateOnce(true);
    props.setUpdateDrawer(true);
    setVisible(false);
  }
  if(propertyName.includes("lastModified") || propertyName.includes("uploadedOn"))
  {
    value = dateFormatter(propertyValue);
  }
  if(propertyName.includes("size"))
  {
    value = sizeFormatter(propertyValue);
  }
  return (<Box className={`tree-node ${styles.root} `}
              style={{paddingInlineStart: indent}}
              onMouseEnter = {() => setMouseOver(true)}
              onMouseLeave = {() => setMouseOver(false)}
          >
           { !visible ? <Typography variant="body2" alignContent="right" alignItems="right" noWrap>
                          {value !== "undefined" ? value : "NA"}
                        </Typography> 
                      : <>
                          <div className={styles.labelGridItem}>
                              <Input fontSize="small" margin="dense" value={inputText} onChange={handleChangeText}/>
                          </div>
                          <IconButton size="small" onClick={handleUpdateProperties} disabled={inputText === ""}>
                            <CheckIcon fontSize="small" className={styles.editIcon} />
                          </IconButton>
                          <IconButton size="small" onClick={handleCancel}>
                            <CloseIcon fontSize="small" className={styles.editIcon}/>
                          </IconButton>
                        </> }
            {mouseOver && propertyName ==="description" && <MenuItem variant="secondary" onClick={handleClick}><EditIcon fontSize="small" /></MenuItem>}
          </Box>)
}
const PropertiesDrawer = ({showProperties, setShowProperties, props}) => {
  const solutionConfig = config.find((solution) => solution.solutionName === props.solutionName);
  const keyList = solutionConfig["PropertyKeys"].split(",");
  const [rowData, setRowData] = useState([]);
  let propertiesData = [];
  for (let key of keyList)
  {
    propertiesData.push({"key": `${key}`, "value": `${props.node.properties[key]}`});
  }
  const handleClose = () => {
    setShowProperties(false);
  }
  const columnDefs = [
    { field: 'key', headerName: 'Property Name', sortable: true, flex: 1, filter: 'agTextColumnFilter', resizable: true, valueGetter: (params) => (params.data.key.replace(/([a-z])([A-Z])/g, '$1 $2')).toUpperCase()},
    { field: 'value', headerName: 'Property Value', sortable: true, flex: 1, filter: 'agTextColumnFilter', resizable: true, cellRenderer: getProperty}
  ];
  function getProperty(params){
    return (<FileProperty propertyValue = {params.data.value}
                          propertyName = {params.data.key} 
                          props = {props}/>);
  }
  useEffect(() => {
    setRowData(propertiesData)
  }, [props.treeData])
  return (
  <Drawer open = {showProperties} onClose = {handleClose} anchor='bottom' sx={{m:4}}>
    <Grid container justifyContent="center" alignItems="center" direction="column" paddingTop="10px">
      <div className={styles.icon}>
          <Typography variant="h6"><TypeIcon isDirectory={props.node.isDirectory} name={props.node.name}/>  {props.node.name}</Typography>
      </div>
    </Grid>
    <Grid container direction="column" justifyContent="center" alignItems="left" paddingTop="20px" paddingLeft="25%">
    <Box sx={{width: '75%', marginLeft: 1}}><div className="ag-theme-material ">
      <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          domLayout='autoHeight'
          rowHeight={32}
          wrapText={false}
          maxBlocksInCache={1}
        />
      </div>
      </Box>
      </Grid>
    <Grid container direction="column" paddingBottom="10px" paddingTop="10px" justifyContent="center" alignItems="center">
      <Button color="primary" variant="contained" onClick={handleClose}>{t('Close')}</Button>
    </Grid>
  </Drawer>
  );
}
const ProgressDrawer = ({showProgress, setShowProgress, downloadFailed, setDownloadFailed}) => {
  const handleClose = () => {
    setDownloadFailed(false);
    setShowProgress(false);
  }
  return (<Drawer open = {showProgress} anchor='bottom' sx={{m:2}}>
  <Grid container direction="column" justifyContent="center" alignItems="center" paddingTop="20px"/>
  {!downloadFailed ? <Grid container direction="column" paddingBottom="10px" justifyContent="center" alignItems="center">
  <Typography variant="h6" paddingBottom="20px">{t("Preparing for download. Zip folder will automatically be downloaded.")}</Typography>
  <CircularProgress paddingBottom="20px"/>
  </Grid>
  : <Grid container direction="column" justifyContent="center" alignItems="center" paddingBottom="20px">
  <Typography variant="h6" paddingBottom="20px">{t("Folder size was too large or something else went wrong. Try downloading files individually.")}</Typography>
  <Button variant="contained" color="primary" onClick={handleClose}> {t('Close')} </Button>
  </Grid>}
</Drawer>)
}

export default CustomNode;